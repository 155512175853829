<template>
  <b-card>
    <div class="row">
      <div class="col-md-12 col-xl-12">
        <h4 class="d-inline"><i class="fa fa-chart-line"></i> Completions Actual Well Cost</h4>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-md-6 col-xl-6 mt-3">
        <h5>Select Project</h5>
        <multiselect :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" v-model="projectid" placeholder="Search For Project" tag-placeholder="Search For Project" track-by="projectid" :options="projectData" label="projectname">
        </multiselect>
      </div>
      <div class="col-md-6 col-xl-6 mt-3 mb-3">
        <h5>Build</h5>
        <b-button class="w-100 mt-1" variant="success" @click="loadInvoices()">
          Build <i class="fa fa-chart-bar"></i>
        </b-button>
      </div>
    </div>
    <div id="invoice-chart">
      <rotate-square2 id="loading-gif" v-show="loading"></rotate-square2>
      <div id="chartdiv" style="width: 100%; height: 640px;"></div>
    </div>
  </b-card>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";

am4core.useTheme(am4themes_animated);
//am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_dataviz);


export default {
  name: 'Chart2',
  components: {
    Multiselect,
    RotateSquare2,
  },
  data: function() {
    return {
      projectData: [],
      projectid: [],
      chartData: [],
      cats: [],
      catData: [],
      catList: [],
      categoryid: [],
      loading: false,
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    async loadInvoices(a){
      if(!this.projectid){
        this.$toasted.show('Error. Please select project.', {type: 'error', duration: '3000'})
        return false;
      }
      this.loading = true;

      var temp = this.projectid.sort(function(a,b) {
        return new Date(a.p_start_date).getTime() - new Date(b.p_start_date).getTime()
      })[0];

      this.startDate = temp.p_start_date;

      var apiData = [];
      this.catList = [];

      var temp = this.projectid.sort(function(a,b) {
        return new Date(a.p_start_date).getTime() - new Date(b.p_start_date).getTime()
      })[0];

      this.startDate = temp.p_start_date;

      var projectids = this.projectid.map(x=>x.projectid)

      let data = JSON.stringify({
        projectids: projectids,
        start_date: this.$moment(this.startDate).startOf('month').format('YYYY-MM-DD'),
      })

      var chartDataWithQuarters = []

      this.$axios.post('/charts/v2/2', data)
      .then(response => {
        chartDataWithQuarters.push(response.data.result[response.data.result.length -1])

        var getChartData = response.data.result.filter(x=>x.label != "Budget");
        console.log(getChartData.length, 'length')

        for (var i = 0; i < getChartData.length; i++) {
          let m = this.$moment(getChartData[i].label).format("M");
          let data = getChartData[i];

          console.log(i, 'index')
          console.log(i + 1, 'index + 1')
          console.log(m, 'month')
          //console.log(m % 3, 'remainder')
          console.log(getChartData.length, '# of months')
          chartDataWithQuarters.push(data)
          if(m % 3 == 0 && i + 1 <= getChartData.length){
            chartDataWithQuarters.push(data)

            var findMonthsInQuarter = getChartData.filter(
              x => this.$moment(getChartData[i].label).isSameOrAfter(this.$moment(x.label).startOf('quarter'), 'month')
              && this.$moment(getChartData[i].label).isSameOrAfter(this.$moment(x.label).endOf('quarter'), 'month'))

            console.log(findMonthsInQuarter)

            let catMap = Object.keys(Object.assign({}, ...findMonthsInQuarter));
            catMap = catMap.filter(x=>x != 'label' && x != 'total_before_divide_by_well_count' && x != 'well_count')

            var chartDataTemp = {
              "label": "Q" + this.$moment(getChartData[i].label).quarter() + "-" + this.$moment(getChartData[i].label).year(),
            }

            for (var x = 0; x < catMap.length; x++) {
              var catTotal = findMonthsInQuarter.reduce(function(total, item){
                if(item.hasOwnProperty(catMap[x])){
                    return total + item[catMap[x]]
                }
                else {
                  return 0;
                }

              }, 0);
              chartDataTemp[catMap[x]] = catTotal;
            }
            chartDataWithQuarters.push(chartDataTemp)
          }
          if(m % 3 != 0 && i + 1 == getChartData.length){
            chartDataWithQuarters.push(data)

            var findMonthsInQuarter = getChartData.filter(
              x => this.$moment(getChartData[i].label).isSameOrAfter(this.$moment(x.label).startOf('quarter'), 'month')
              && this.$moment(getChartData[i].label).isSameOrBefore(this.$moment(x.label).endOf('quarter'), 'month'))

            console.log(findMonthsInQuarter)

            let catMap = Object.keys(Object.assign({}, ...findMonthsInQuarter));
            catMap = catMap.filter(x=>x != 'label' && x != 'total_before_divide_by_well_count' && x != 'well_count')

            var chartDataTemp = {
              "label": "Q" + this.$moment(getChartData[i].label).quarter() + "-" + this.$moment(getChartData[i].label).year(),
            }

            for (var x = 0; x < catMap.length; x++) {
              var catTotal = findMonthsInQuarter.reduce(function(total, item){
                if(item.hasOwnProperty(catMap[x])){
                    return total + item[catMap[x]]
                }
                else {
                  return 0;
                }

              }, 0);
              chartDataTemp[catMap[x]] = catTotal;
            }

            chartDataWithQuarters.push(chartDataTemp)
          }
          if(m == 12 || (i + 1) == getChartData.length){
            console.log('ytd')
            chartDataWithQuarters.push(data)

            var findMonthsInQuarter = getChartData.filter(
              x => this.$moment(getChartData[i].label).isSameOrAfter(this.$moment(x.label).startOf('year'), 'month')
              && this.$moment(getChartData[i].label).isSameOrBefore(this.$moment(x.label).endOf('year'), 'month'))

            console.log(findMonthsInQuarter)

            let catMap = Object.keys(Object.assign({}, ...findMonthsInQuarter));
            catMap = catMap.filter(x=>x != 'label' && x != 'total_before_divide_by_well_count' && x != 'well_count')

            var chartDataTemp = {
              "label": "YTD-" + this.$moment(getChartData[i].label).year(),
            }

            for (var x = 0; x < catMap.length; x++) {
              var catTotal = findMonthsInQuarter.reduce(function(total, item){
                if(item.hasOwnProperty(catMap[x])){
                    return total + item[catMap[x]]
                }
                else {
                  return 0;
                }

              }, 0);
              chartDataTemp[catMap[x]] = catTotal;
            }
            chartDataWithQuarters.push(chartDataTemp)
          }
        }

        //console.log(chartDataWithQuarters)
        this.createChart(chartDataWithQuarters)
        //this.createChart(chartDataWithQuarters)
      })

      // var projectids = this.projectid.map(x=>x.projectid)
      //
      // let data = JSON.stringify({
      //   projectid: projectids,
      //   start_date: this.$moment(new Date).startOf('year').format('YYYY-MM-DD'),
      //   end_date: this.$moment(new Date).endOf('month').format('YYYY-MM-DD')
      // })
      //
      // const chartAwait = await this.$axios.post('/manager/chart/two', data)
      // .then(response => {
      //   if(response.data.result.length > 0){
      //     var temp = new Object();
      //     temp['label'] = 'YTD';
      //     var group = this.lodash.chain(response.data.result)
      //                 .groupBy('category')
      //                 .map((wells, category) => ({ wells, category }))
      //                 .value();
      //
      //     for (var i = 0; i < group.length; i++) {
      //
      //       var count = this.lodash.uniq(group[i].wells.map(x=>x.uwi));
      //
      //       this.catList.push({
      //         category: group[i].category,
      //         count: count.length,
      //       })
      //       for (var y = 0; y < group[i].wells.length; y++) {
      //         //var category = group[i].category.replace(/\s+/g, '_') + "_" + group[i].wells[y].projectname.replace(/\s+/g, '_')
      //         var category = group[i].category.replace(/\s+/g, '_')
      //         var total = group[i].wells.reduce(function(total, item){
      //           return total + item.cat_sum
      //         },0);
      //         temp[category] = this.formatPrice(total / count.length);
      //       }
      //     }
      //     apiData.push(temp);
      //   }
      // })
      //
      // for (var i = 0; i < timeValues.length; i++) {
      //   let data2 = JSON.stringify({
      //     projectid: projectids,
      //     start_date: this.$moment(timeValues[i]).startOf('month').format('YYYY-MM-DD'),
      //     end_date: this.$moment(timeValues[i]).endOf('month').format('YYYY-MM-DD')
      //   })
      //   const chartAwait2 = await this.$axios.post('/manager/chart/two', data2)
      //   .then(response => {
      //     if(response.data.result.length > 0){
      //
      //       var temp = new Object();
      //       temp['label'] = this.$moment(timeValues[i]).format('MM-YYYY');
      //       var group = this.lodash.chain(response.data.result)
      //                   .groupBy('category')
      //                   .map((wells, category) => ({ wells, category }))
      //                   .value();
      //
      //       for (var t = 0; t < group.length; t++) {
      //         var count = this.lodash.uniq(group[t].wells.map(x=>x.uwi));
      //         this.catList.push({
      //           category: group[t].category,
      //           count: count.length,
      //         })
      //         for (var y = 0; y < group[t].wells.length; y++) {
      //           //var category = group[i].category.replace(/\s+/g, '_') + "_" + group[i].wells[y].projectname.replace(/\s+/g, '_')
      //           var category = group[t].category.replace(/\s+/g, '_')
      //           var total = group[t].wells.reduce(function(total, item){
      //             return total + item.cat_sum
      //           },0);
      //           temp[category] = this.formatPrice(total / count.length);
      //         }
      //       }
      //       apiData.push(temp);
      //     }
      //   }).catch(error => {
      //   })
      // }
      //this.createChart(apiData)
    },
    async createChart(apiData) {
      var chart = am4core.create("chartdiv", am4charts.XYChart);

      chart.colors.list = [
        am4core.color("#5c99d6"),
        am4core.color("#f07c2d"),
        am4core.color("#a2a5a5"),
        am4core.color("#72ac4e"),
        am4core.color("#947500")
      ];

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "label";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 10;

      categoryAxis.renderer.labels.template.rotation = 320;
      categoryAxis.renderer.cellStartLocation = 0.2;
      categoryAxis.renderer.cellEndLocation = 0.8;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      valueAxis.title.text = "";
      valueAxis.calculateTotals = true;

      valueAxis.maxPrecision = 0;

      // Create series
      function createSeries(field, name) {
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "label";

        // Make it stacked
        series.stacked = true;
        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: ${valueY}\nWell Count: {well_count}";

        //var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        //labelBullet.label.text = "${valueY}";
        //labelBullet.locationY = 0.5;

        return series;
      }

      //var catMap = this.lodash.uniq(apiData.map(x=>x.category))
      let catMap = Object.keys(Object.assign({}, ...apiData));
      catMap = catMap.filter(x=>x != 'label' && x != 'total_before_divide_by_well_count' && x != 'well_count')

      for (var i = 0; i < catMap.length; i++) {
        createSeries(catMap[i], catMap[i].replace(/_/g, ' '));
      }

      //var catMap2 = this.lodash.uniq(apiData.map(x=>x.categoryName))
      var projectMap = this.projectid.map(x=>x.projectid)
      var temp3 = new Object();

      this.catList = this.lodash.uniqBy(this.catList, 'category')

      // for (var i = 0; i < projectMap.length; i++) {
      //   for (var x = 0; x < this.catList.length; x++) {
      //     let data = JSON.stringify({
      //       projectid: projectMap[i],
      //       category: this.catList[x].category,
      //       start_date: this.$moment(new Date).startOf('year').format('YYYY-MM-DD'),
      //       end_date: this.$moment(new Date).endOf('month').format('YYYY-MM-DD')
      //     })
      //
      //     const chartAwaitEstimates = await this.$axios.post('/manager/chart/wss_estimate', data)
      //     console.log(apiData)
      //     if(chartAwaitEstimates.data.result.length > 0){
      //       var find = this.catList[x].category.replace(/\s+/g, '_');
      //
      //       var index = Object.keys(apiData).indexOf(find);
      //       console.log(index, this.catList[x].category,  find);
      //
      //       //var total = apiData[index][this.catList[x].category.replace(/_/g, ' ')]
      //     }
      //
      //     const chartAwaitBudget = await this.$axios.post('/manager/chart/budget', data)
      //     .then(response => {
      //       if(response.data.result.length > 0){
      //         temp3['label'] = "Budget";
      //         for (var y = 0; y< response.data.result.length; y++) {
      //           var temp = this.projectData.filter(x => x.projectid === projectMap[i])
      //           var projectname = temp[0].projectname
      //           //var category = this.catList[x].category.replace(/\s+/g, '_') + "_" + temp[0].projectname.replace(/\s+/g, '_')
      //           var category = this.catList[x].category.replace(/\s+/g, '_')
      //           temp3[category] = this.formatPrice((response.data.result[y].budget_sum * response.data.result[y].well_count) / this.catList[x].count)
      //         }
      //       }
      //     }).catch(error => {
      //     })
      //   }
      // }
      // apiData.unshift(temp3);

      chart.scrollbarX = new am4core.Scrollbar();
      chart.cursor = new am4charts.XYCursor();

      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.margin('10', '0', '0', '0')
      chart.legend.position = "bottom";

      chart.legend.useDefaultMarker = true;
      var marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;
      marker.stroke = am4core.color("#ccc");
      //chart.legend.markers.template.disabled = true;

      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = "Invoice Chart Export";
      chart.exporting.menu.items = [{
        "label": "Export <i class='fa fa-export'></i>",
        "menu": [
          {
            "label": "Image",
            "menu": [
              { "type": "png", "label": "PNG" },
              { "type": "jpg", "label": "JPG" },
              { "type": "gif", "label": "GIF" },
              { "type": "svg", "label": "SVG" },
            ]
          }, {
            "label": "Data",
            "menu": [
              { "type": "json", "label": "JSON" },
              { "type": "csv", "label": "CSV" },
              { "type": "xlsx", "label": "XLSX" }
            ]
          }, {
            "label": "Print", "type": "print"
          }
        ]
      }];
      chart.data = apiData;
      //console.log(JSON.stringify(apiData))
      // Legend
      this.loading = false;
    },
    getProjectName(id){
      this.projectData.filter(x.projectid === id)
      return this.projectData[0].projectid
    }
  },
  mounted() {
    if(this.$session.get('user') == 'pm'){
      this.$axios.get('wts/get/projects').then(response => {
        this.projectData = response.data.result.reverse()
      })
      .catch(error => {

      })
    }
    else{
      this.$axios.get('manager/get/projects').then(response => {
        this.projectData = response.data.result.reverse()
      })
      .catch(error => {

      })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .mx-input {
    padding: 20px 15px !important;
  }

  #invoice-chart {
    position: relative;
    .amcharts-amexport-item.amcharts-amexport-item-level-0 {
      top: -50px;
      width: 100px;
      height: inherit;
      padding: 0.5em;
    }
    .amcharts-amexport-item {
      border: none;
      background: #4dbd74;

      a {
        color: #fff;
      }
    }
    .canvasjs-chart-credit {
      display: none !important;
    }

    #loading-gif {
      z-index: 9999;
      position: absolute;
      top: 200px;
      right: 50%;
    }
  }
</style>
